import React from "react";
import Link from "gatsby-link";

import Layout from "../components/Layout";

export default function Index() {
  return (
    <Layout>
      <div className="landing">
        <div className="landing-welcome site-content_inner">
          <p>
            Herzlich Willkommen{" "}
            <span className="sans">auf&nbsp;den&nbsp;Seiten&nbsp;des</span>
            <br /> Fördervereins des{" "}
            <span className="no-wrap">Luisen-Gymnasiums</span> Düsseldorf.
          </p>
        </div>

        <div className="landing-text1 site-content_inner">
          <p>
            Die „Gesellschaft der Freunde und Förderer des Städtischen
            Luisen-Gymnasiums zu Düssel&shy;dorf e.V.“ versteht sich als
            Gemein&shy;schaft von Eltern, ehemaligen Schüler*innen sowie
            Lehrer*innen, die auf viel&shy;fältige Weise Bildung und
            Er&shy;ziehung fördert und das schulische Leben unter&shy;stützt.
          </p>
        </div>

        <div className="landing-quote">
          <div className="landing-quote_content">
            <span className="marks">&rdquo;</span>
            <p>
              Wir wünschen uns eine Schule, die mehr bieten kann als das
              Minimum.
            </p>
            <div className="quotee">
              <p>Martin Heil</p>
              <span>
                Mitglied des <Link to="/vorstand">Vorstands</Link>
              </span>
            </div>
          </div>
        </div>

        <div className="landing-text2 site-content_inner">
          <p>
            Alle Mitglieder des Förder&shy;vereins verbindet das gemein&shy;same
            Interesse an einer Schule, die allen unseren Schüler*innen optimale
            Bedin&shy;gun&shy;gen für das Leben und Lernen in der
            Gemein&shy;schaft bietet.
          </p>
          <p>
            Der Förderverein unter&shy;stützt den Ausbau eines
            weit&shy;ge&shy;fächer&shy;ten Bildungs&shy;an&shy;ge&shy;botes, das
            die Schüler*innen auf die Anfor&shy;de&shy;rungen von Studium und
            Beruf vor&shy;be&shy;reitet.
          </p>
          <p>
            Helfen Sie mit, diese{" "}
            <Link to="/was-wir-tun">
              viel&shy;fäl&shy;ti&shy;gen Auf&shy;ga&shy;ben
            </Link>{" "}
            auch weiter&shy;hin zu er&shy;fül&shy;len. Unter&shy;stüt&shy;zen
            Sie den Förder&shy;verein. Engagieren Sie sich. Der
            Förder&shy;verein braucht Sie – und Ihr Kind braucht den
            Förder&shy;verein.
          </p>
          <p className="learn-more site-content_inner">
            Erfahren Sie hier,{" "}
            <Link to="/ihre-unterstuetzung">wie Sie uns helfen können</Link>.
          </p>
        </div>
        {/* <Link to="/form">go to form</Link> */}
      </div>
    </Layout>
  );
}
